import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Edges from "../edges"
import Textarea from "./Textarea"
import PlayIcon from "../../../static/images/icons/play.svg" // Assuming you have a play icon like in the TextareaVideo component
import { useStore } from "store"

export default function TextImage({ alignment, content, image, link }) {
  const img = getImage(image.localFile)
  const dispatch = useStore()[1]

  const showLightBox = () => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: 0,
        slides: [{ link, image }],
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <EdgesContainer size="md">
      <Container alignment={alignment}>
        <ImageContainer alignment={alignment} onClick={link ? showLightBox : null}>
          {img && <StyledImage image={img} alt={image?.altText || ""} />}
          {link && <PlayButton />}
        </ImageContainer>
        <TextContainer>
          <TextEdges size="lg">
            <Textarea text={content} />
          </TextEdges>
        </TextContainer>
      </Container>
    </EdgesContainer>
  )
}

const EdgesContainer = styled(Edges)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const Container = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.alignment === "right" ? "wrap-reverse" : "wrap")};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  order: ${(props) => (props.alignment === "right" ? 3 : 1)};
  margin-bottom: 20px;
  position: relative; // To position the play button

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: calc(50% - 15px);
    justify-content: ${(props) => (props.alignment === "right" ? "flex-end" : "flex-start")};
  }

  cursor: ${(props) => (props.onClick ? "pointer" : "default")}; // Only show pointer cursor if the image is clickable
`

const StyledImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;

  object-fit: cover;

  @media (min-width: 576px) {
    width: 550px;
  }
`

const PlayButton = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 55px;
  filter: drop-shadow(2px 4px 6px black);
  opacity: 0.95;
  width: 55px;
`

const TextContainer = styled.div`
  width: 100%;
  order: 2;

  @media (min-width: 768px) {
    width: calc(50% - 15px);
  }
`

const TextEdges = styled(Edges)`
  display: flex;
  justify-content: center;
  align-items: center;
`
